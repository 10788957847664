// src/LandingPage.js

import React, { useEffect, useState } from 'react';
import NFTApi from '../api/NFTApi';
import LandingTableComponent from './LandingTableComponent';
import Accordion from './AccordionComponent';


export default function LandingPage() {
    const [owner, setOwner] = useState(null);
    const [landingPageError, setLandingPageError] = useState(false);
    const [dataIsLoaded, setDataIsLoaded] = useState(false);
    const [langingData, setLangingData] = useState([]);
    const [latestUpdate, setlatestUpdate] = useState("");
    const [realmsUpdate, setrealmsUpdate] = useState("");

    useEffect(() => {
        async function fetchData() {
            try {
                const [langingDataApi] = await Promise.all([
                    NFTApi.getLandingPage(),
                ]);
                console.log(langingDataApi.data['summary'])
                setLangingData(langingDataApi.data['summary']);
                setlatestUpdate(langingDataApi.data['latest_update'])
                setrealmsUpdate(langingDataApi.data['realms_upate'])
                setDataIsLoaded(true);
            } catch (error) {
                console.log("Error fetching data" + error);
                setLandingPageError(true);
                setDataIsLoaded(true);
            }
        }
        fetchData();
    }, []);

    if (!dataIsLoaded) {
        return <div className="loading-nfts"><h1> Welcome! Traveler, we are gathering the realms for you. Please hold tight.</h1></div>;
    }

    if (landingPageError) {
        return <div className="loading-nfts"><h1>There was an error loading the data.</h1></div>;
    }

    if (dataIsLoaded) {
        return (
            <div className="landing-page">
                <img src="/images/cronos_logo.svg" alt="Cronos Logo" className="logo-cronos" />
                <img src="/images/white_logo.png" alt="Project Logo" className="logo-project" />

                <h3>Welcome to Your Journey Through the Cronos Ragdolls Realms!</h3>

                <Accordion title="About the Cronos Ragdolls Realms">
                    <p>Show your Cronos NFT collection. The Cronos Ragdolls Realms are public, so your frens can see them.</p>
                    <p>For every Cronos Ragdoll NFT you own, you'll have a new room in your realm.</p>
                    <p>Each room allows you to display up to 10 NFTs.</p>
                    <p>Your NFT collection is updated every day.</p>
                    <p>Did you buy or sell a RAG? Visit our Discord server to check when we will update the realms.</p>
                    <p><a href="https://discord.gg/GjHnGSMJgk" target="_blank">Join our Discord Server</a></p>
                </Accordion>

                <Accordion title="Tips for Adventurers">
                    <p>Visit the realms at different times of the day.</p>
                    <p>Use the filters and sort the table by clicking on the header.</p>
                </Accordion>

                <Accordion title="When was the data updated?">
                    <p>NFTs Collections updated on: {latestUpdate.substring(0, 10)}</p>
                    <p>Realms updated on: {realmsUpdate.substring(0, 10)}</p>
                    <p>Visit our Discord to request a Realm update.</p>
                </Accordion>

                <LandingTableComponent data={langingData} />
            </div>
        )
    }

}
