// shader-grid-glitch.js
import * as three from "three";
import fragment from './ShaderFragment.js';
import vertex from './ShaderVertex.js';
require("aframe");


const AFRAME = window.AFRAME;
const THREE = window.THREE;
AFRAME.registerShader('grid-glitch', {
    schema: {
        color: { type: 'color', is: 'uniform' },
        timeMsec: { type: 'time', is: 'uniform' }
    },

    vertexShader: vertex,
    fragmentShader: fragment
});