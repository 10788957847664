import React from 'react';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';

export default function LandingTableComponent({ data }) {
    function openPage(address) {
        const url = window.location.origin + `/${address}`;
        window.open(url, '_blank');
    }

    function openSlideshow(address) {
        const url = `https://www.slideshow.cronosragdolls.com/${address}`;
        window.open(url, '_blank');
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Cronos Address',
                id: 'address',
                accessor: 'owner',
            },
            {
                Header: 'Realms',
                id: 'actions',
                Cell: ({ row }) => (
                    <button onClick={() => openPage(row.original.owner)}>Realm</button>
                ),
            },
            {
                Header: 'Slideshow',
                id: 'to_slideshow',
                Cell: ({ row }) => (
                    <button onClick={() => openSlideshow(row.original.owner)}>Slideshow</button>
                ),
            },
            {
                Header: 'Ragdolls',
                accessor: 'ragdolls',
            },
            {
                Header: 'Total NFTs',
                accessor: 'nft_total',
            },
            {
                Header: 'NFT Collections',
                accessor: 'contracts_total',
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using rows, we'll use page,
        setFilter,
        state: { pageIndex, pageSize },
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setPageSize,
    } = useTable(
        { columns, data, initialState: { pageIndex: 0 } },
        useFilters,
        useSortBy,
        usePagination
    );

    // Filter change handler
    const handleFilterChange = (e, column) => {
        const value = e.target.value || undefined;
        setFilter(column, value);
    };

    return (
        <div className="scrollable-container">
            <div className="filter-inputs">
                {headerGroups[0].headers.map((column, i) => (
                    // If column.render('Header') != 'Realms' or 'Slideshow', show the filter input
                    column.render('Header') !== 'Realms' && column.render('Header') !== 'Slideshow' &&
                    <div className="filter-input" key={i}>
                        <input
                            placeholder={column.render('Header')}
                            onChange={(e) => handleFilterChange(e, column.id)}
                        />
                    </div>
                ))}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
            <div className="table-container">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' ▼'
                                                    : ' ▲'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        // Check if the column id is 'address'
                                        if (cell.column.id === 'address') {
                                            const fullAddress = cell.value;
                                            // Show the first 4 and last 4 characters, separated by "..."
                                            const truncatedAddress = `${fullAddress.slice(0, 6)}...${fullAddress.slice(-6)}`;
                                            return <td className="cronos-address" {...cell.getCellProps()}>{truncatedAddress}</td>;
                                        }
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <br />
                <div>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                    </button>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
                        </strong>{' '}
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}
