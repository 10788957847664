// analytics.js
import ReactGA from "react-ga4";

export const initGA = () => {
    ReactGA.initialize("G-R6X2DHJ6GW", {
        debug: process.env.NODE_ENV === 'development',
    });
};

export const logPageView = () => {
    ReactGA.send('pageview');
};

export const logVisitRealm = () => {
    ReactGA.send("visit_realm");
};