require("aframe");
const AFRAME = window.AFRAME;
const THREE = window.THREE;

AFRAME.registerComponent("mobile-look-controls", {
    init: function () {
        const cameraEl = document.querySelector("#head");
        const cameraObject = cameraEl.object3D;
        let isTouching = false;
        let initialTouch = { x: 0, y: 0 };

        this.el.addEventListener("touchstart", function (e) {
            isTouching = true;
            initialTouch.x = e.touches[0].clientX;
            initialTouch.y = e.touches[0].clientY;
        });

        this.el.addEventListener("touchmove", function (e) {
            if (!isTouching) return;

            const deltaX = e.touches[0].clientX - initialTouch.x;
            const deltaY = e.touches[0].clientY - initialTouch.y;

            cameraObject.rotation.y -= deltaX * 0.005;  // Adjust sensitivity here
            cameraObject.rotation.x -= deltaY * 0.005;  // Adjust sensitivity here

            initialTouch.x = e.touches[0].clientX;
            initialTouch.y = e.touches[0].clientY;
        });

        this.el.addEventListener("touchend", function () {
            isTouching = false;
        });
    }
});
