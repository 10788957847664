require("aframe");
const AFRAME = window.AFRAME;
const THREE = window.THREE;
const PROXY_FLAG = '__keyboard-controls-proxy';
const KeyboardEvent = window.KeyboardEvent;

AFRAME.registerComponent('customkeyboard-controls', {
    init: function () {
        this.dVelocity = new THREE.Vector3();
        this.localKeys = {};
        this.listeners = {
            keydown: this.onKeyDown.bind(this),
            keyup: this.onKeyUp.bind(this),
            blur: this.onBlur.bind(this),
            onContextMenu: this.onContextMenu.bind(this),
        };
    },
    /*******************************************************************
    * Movement
    */
    isVelocityActive: function () {
        return this.data.enabled && !!Object.keys(this.getKeys()).length;
    },

    getVelocityDelta: function () {
        const data = this.data;
        const keys = this.getKeys();
        const now = performance.now(); // Current timestamp in milliseconds

        // Add the keyState object to store the state of each key
        this.keyState = this.keyState || {
            KeyW: { pressed: false, timestamp: 0 },
            KeyA: { pressed: false, timestamp: 0 },
            KeyS: { pressed: false, timestamp: 0 },
            KeyD: { pressed: false, timestamp: 0 },
            ArrowUp: { pressed: false, timestamp: 0 },
            ArrowLeft: { pressed: false, timestamp: 0 },
            ArrowDown: { pressed: false, timestamp: 0 },
            ArrowRight: { pressed: false, timestamp: 0 }
        };

        // Acceleration value (you can change this to adjust the acceleration)
        const acceleration = 1;

        this.dVelocity.set(0, 0, 0);
        if (data.enabled) {
            // Iterate through each keyState
            for (const key in this.keyState) {
                if (keys[key]) { // if the key is currently pressed
                    if (!this.keyState[key].pressed) { // if the key was not already pressed
                        this.keyState[key].pressed = true; // mark it as pressed
                        this.keyState[key].timestamp = now; // store the current timestamp
                    } else { // if the key was already pressed
                        // Calculate how long the key has been pressed
                        let speed = Math.min((now - this.keyState[key].timestamp) / 250, 4); // Adjusted the deltaTime calculation                        

                        // Apply the deltaTime to the correct axis
                        if (key === 'KeyW' || key === 'ArrowUp') { this.dVelocity.z -= speed; }
                        else if (key === 'KeyA' || key === 'ArrowLeft') { this.dVelocity.x -= speed; }
                        else if (key === 'KeyS' || key === 'ArrowDown') { this.dVelocity.z += speed; }
                        else if (key === 'KeyD' || key === 'ArrowRight') { this.dVelocity.x += speed; }
                    }
                } else if (this.keyState[key].pressed) { // if the key was released
                    this.keyState[key].pressed = false; // mark it as not pressed
                    this.keyState[key].timestamp = 0; // reset the timestamp
                }
            }
        }
        return this.dVelocity.clone();
    },


    /*******************************************************************
  * Events
  */

    play: function () {
        this.attachEventListeners();
    },

    pause: function () {
        this.removeEventListeners();
    },

    attachEventListeners: function () {
        window.addEventListener("contextmenu", this.listeners.onContextMenu, false);
        window.addEventListener("keydown", this.listeners.keydown, false);
        window.addEventListener("keyup", this.listeners.keyup, false);
        window.addEventListener("blur", this.listeners.blur, false);
    },

    onContextMenu: function () {
        for (const code in this.localKeys) {
            if (this.localKeys.hasOwnProperty(code)) {
                delete this.localKeys[code];
            }
        }
    },

    removeEventListeners: function () {
        window.removeEventListener('keydown', this.listeners.keydown);
        window.removeEventListener('keyup', this.listeners.keyup);
        window.removeEventListener('blur', this.listeners.blur);
    },

    onKeyDown: function (event) {
        if (AFRAME.utils.shouldCaptureKeyEvent(event)) {
            this.localKeys[event.code] = true;
            this.emit(event);
        }
    },

    onKeyUp: function (event) {
        if (AFRAME.utils.shouldCaptureKeyEvent(event)) {
            delete this.localKeys[event.code];
            this.emit(event);
        }
    },

    onBlur: function () {
        for (const code in this.localKeys) {
            if (this.localKeys.hasOwnProperty(code)) {
                delete this.localKeys[code];
            }
        }
    },

    emit: function (event) {
        // TODO - keydown only initially?
        // TODO - where the f is the spacebar

        // Emit original event.
        if (PROXY_FLAG in event) {
            // TODO - Method never triggered.
            this.el.emit(event.type, event);
        }

        // Emit convenience event, identifying key.
        this.el.emit(event.type + ':' + event.code, new KeyboardEvent(event.type, event));
        if (this.data.debug) console.log(event.type + ':' + event.code);
    },
    /*******************************************************************
    * Accessors
    */
    isPressed: function (code) {
        return code in this.getKeys();
    },

    getKeys: function () {
        if (this.isProxied()) {
            return this.el.sceneEl.components['proxy-controls'].getKeyboard();
        }
        return this.localKeys;
    },

    isProxied: function () {
        const proxyControls = this.el.sceneEl.components['proxy-controls'];
        return proxyControls && proxyControls.isConnected();
    }
});