// shader-grid-glitch.js
import * as three from "three";
import fragment from './ShaderGradientSkyFragment.js';
import vertex from './ShaderGradientSkyVertex.js';
require("aframe");


const AFRAME = window.AFRAME;
const THREE = window.THREE;
AFRAME.registerShader('sky-gradient', {
    schema: {
        color1: { type: 'vec3', default: '255 210 0', is: 'uniform' },
        color2: { type: 'vec3', default: '255 210 0', is: 'uniform' },
        color3: { type: 'vec3', default: '244 123 32', is: 'uniform' },
        color4: { type: 'vec3', default: '244 123 32', is: 'uniform' },
        color5: { type: 'vec3', default: '240 81 51', is: 'uniform' },
        timeMsec: { type: 'time', is: 'uniform' }
    },
    vertexShader: vertex,
    fragmentShader: fragment,
});






