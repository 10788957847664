require("aframe");
const AFRAME = window.AFRAME;
const THREE = window.THREE;

module.exports = AFRAME.registerComponent('oculus-thumbstick-movement', {
    init: function () {
        // Reference to the cameraRig and camera entities
        this.cameraRig = document.querySelector('#cameraRig');
        this.camera = document.querySelector('#camera');

        // Listen to thumbstickmoved event for movement
        this.el.addEventListener('thumbstickmoved', this.thumbstickMoved.bind(this));
    },

    thumbstickMoved: function (evt) {
        // Get the thumbstick coordinates
        let x = evt.detail.x;
        let y = -evt.detail.y;  // Reverse the y-direction

        // Create a direction vector
        let dir = new THREE.Vector3(x, 0, y);

        // Rotate the direction vector to align with the camera's direction
        dir.applyQuaternion(this.camera.object3D.quaternion);

        // Scale the vector based on your speed requirements (adjust as needed)
        dir.multiplyScalar(0.1);

        // Calculate the new position
        let currentPosition = this.cameraRig.object3D.position.clone();
        let newPosition = currentPosition.add(dir);

        // Apply the new position to the cameraRig
        this.cameraRig.setAttribute('position', {
            x: newPosition.x,
            y: newPosition.y,
            z: newPosition.z
        });
    }
});
