const fragment = `
uniform vec3 color1;
uniform vec3 color2;
uniform vec3 color3;
uniform vec3 color4;
uniform vec3 color5;
varying vec3 vWorldPosition;
uniform float timeMsec;
uniform float offset;

void main() {
    float time = timeMsec / 1000.0;
    // float h = normalize(vWorldPosition).y + sin(time) * 0.05; // Offset height based on time
    float h = normalize(vWorldPosition + offset).y;
    h = fract(h); // Wrap around at the top

    // Convert colors to 0-1 range
    vec3 c1 = color1 / 255.0;
    vec3 c2 = color2 / 255.0;
    vec3 c3 = color3 / 255.0;
    vec3 c4 = color4 / 255.0;
    vec3 c5 = color5 / 255.0;

    vec3 interpolatedColor;

    if (h < 0.25) {
        interpolatedColor = mix(c1, c2, h / 0.25);
    } else if (h < 0.5) {
        interpolatedColor = mix(c2, c3, (h - 0.25) / 0.25);
    } else if (h < 0.75) {
        interpolatedColor = mix(c3, c4, (h - 0.5) / 0.25);
    } else {
        interpolatedColor = mix(c4, c5, (h - 0.75) / 0.25);
    }

    gl_FragColor = vec4(interpolatedColor, 1.0);
}
`;

export default fragment;