import axios from 'axios';

const BASE_URL = 'https://galleryapi.cronosragdolls.com/api';

const headers = {
    'Content-Type': 'application/json',
};

const headersFile = {
};

// Assuming you have a way to store and retrieve tokens
// const token = retrieveToken();
// if (token) {
//   headers['Authorization'] = `Bearer ${token}`;
// }

const apiClient = axios.create({
    baseURL: BASE_URL,
    headers: headers,
});

const apiClientFile = axios.create({
    baseURL: BASE_URL,
    headers: headersFile,
});

const NFTApi = {
    login: (state) => apiClient.get(`/login?state=${state}`),

    callback: (code, state) => apiClient.get(`/callback?code=${code}&state=${state}`),

    getDiscordUser: (signedRequest) => apiClient.post('/get_discord_user', signedRequest),

    getRagdollerNFTs: (tokenId) => apiClient.get(`/ragdoller_nfts/${tokenId}`),

    getWalletNFTs: (tokenId) => apiClient.get(`/wallet_nfts/${tokenId}`),

    getRagdollersSummary: () => apiClient.get('/ragdollers_summary/'),

    getLandingPage: () => apiClient.get('/landingpage/'),

    getNFTMedia: (contract, tokenId) => apiClientFile.get(`/get_nft_media/${contract}/${tokenId}`),

    getGalleryFile: (owner, theme, filetype) => apiClientFile.get(`/get_gallery_file/${owner}/${theme}/${filetype}`),

    postDreamRequest: (tokenId, dreamRequest) => apiClient.post(`/dream_request/${tokenId}`, dreamRequest),

    getDreams: (signedRequest) => apiClient.post('/get_dreams/', signedRequest),

    getDreamImage: (wallet, tokenId, image) => apiClientFile.get(`/get_dream_image/${wallet}/${tokenId}/${image}`),
};

export default NFTApi;
