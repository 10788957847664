// shader-matcap.js
import * as three from "three";
require("aframe");

const AFRAME = window.AFRAME;
const THREE = window.THREE;

AFRAME.registerShader('matcap-shader-gradient', {
  schema: {
    matcap: { type: 'asset', is: 'uniform' }
  },

  init: function () {
    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 256;
    const ctx = canvas.getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, 256, 0);
    gradient.addColorStop(0, 'red');
    gradient.addColorStop(0.5, 'green');
    gradient.addColorStop(1, 'blue');

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;

    this.material = new THREE.ShaderMaterial({
      uniforms: {
        matcap: { type: 't', value: texture }
      },
      vertexShader: this.vertexShader,
      fragmentShader: this.fragmentShader
    });
  },

  vertexShader: `
    varying vec3 v_normal;
    void main() {
      v_normal = normal;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,

  fragmentShader: `
    varying vec3 v_normal;
    uniform sampler2D matcap;
    
    void main() {
      vec2 uv = (v_normal.xy / 2.0) + 0.5;
      gl_FragColor = texture2D(matcap, uv);
    }
  `
});
