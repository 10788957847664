const vertex = `
varying vec3 vWorldPosition;
uniform float timeMsec;

void main() {
	float time = timeMsec / 1000.0;
	vec4 worldPosition = modelMatrix * vec4(position, 1.0);
    
    // Apply rotation around Y-axis
    float angle = time;
    float cosAngle = cos(angle);
    float sinAngle = sin(angle);
    mat3 rotationMatrix = mat3(
        cosAngle, 0.0, sinAngle,
        0.0, 1.0, 0.0,
        -sinAngle, 0.0, cosAngle
    );
    
    vWorldPosition = rotationMatrix * worldPosition.xyz;
    
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}

`;

export default vertex;