require("aframe");
const AFRAME = window.AFRAME;
const THREE = window.THREE;

AFRAME.registerComponent('click-listener', {
    init: function () {
        this.el.addEventListener('click', function (evt) {
            console.log('Entity clicked!');
        });
    }
});