require("aframe");

const AFRAME = window.AFRAME;
const THREE = window.THREE;

AFRAME.registerComponent('look-at-player', {
  schema: {
    fixRotationX: { type: 'boolean', default: false },
    fixRotationY: { type: 'boolean', default: false },
    fixRotationZ: { type: 'boolean', default: false }
  },

  init: function () {
    this.cameraEl = document.querySelector('#cameraRig');
  },

  tick: function () {
    var position = this.el.getAttribute('position');
    var cameraPosition = this.cameraEl.getAttribute('position');

    var rotationX = -Math.atan2(cameraPosition.y - position.y, Math.sqrt(Math.pow(cameraPosition.x - position.x, 2) + Math.pow(cameraPosition.z - position.z, 2))) * 180 / Math.PI;
    var rotationY = Math.atan2(cameraPosition.x - position.x, cameraPosition.z - position.z) * 180 / Math.PI;

    var newRotation = {
      x: this.data.fixRotationX ? this.el.getAttribute('rotation').x : rotationX,
      y: this.data.fixRotationY ? this.el.getAttribute('rotation').y : rotationY,
      z: this.data.fixRotationZ ? this.el.getAttribute('rotation').z : 0
    };

    this.el.setAttribute('rotation', newRotation);
  }
});
