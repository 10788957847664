import * as three from "three";
require("aframe");

const AFRAME = window.AFRAME;
const THREE = window.THREE;

AFRAME.registerShader('matcap-shader', {
  schema: {
    matcap: { type: 'asset', is: 'uniform' }
  },

  init: function () {
    // Create a material with the same type as this.material (it might be ShaderMaterial)
    // and initialize the uniforms you want to use.
    this.material = new THREE.ShaderMaterial({
      uniforms: {
        matcap: { value: null } // We will set the actual value when the texture is loaded
      },
      vertexShader: `
        varying vec3 v_normal;
  
        void main() {
          v_normal = normalize(normalMatrix * normal);
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        varying vec3 v_normal;
        uniform sampler2D matcap;
  
        void main() {
          vec2 uv = v_normal.xy * 0.5 + 0.5;
          gl_FragColor = texture2D(matcap, uv);
        }
      `
    });

    const loader = new THREE.TextureLoader();
    // Load the texture.
    loader.load('images/matcaps/matcap_nidorx.jpg', (texture) => {
      // Now you can assign the loaded texture to the material.
      this.material.uniforms.matcap.value = texture;
      this.material.needsUpdate = true; // This makes sure the material updates with the new uniform.
    });
  }
});
