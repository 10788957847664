// src/components/MainScene.js
import React, { useEffect, useState } from 'react';
import { NFTApi } from '../../../api';
import { NFTObject } from '.';
import vertex from '../Shaders/ShaderVertex';
import { getCookieConsentValue } from "react-cookie-consent";
import { logVisitRealm } from "../../../analytics";

require("aframe");
require('aframe-extras');
require('aframe-environment-component');
require('@c-frame/aframe-particle-system-component');
require('../Shaders/ShaderGridGlitch');
require('../Shaders/ShaderHologram');
require('../Shaders/ShaderMatcap');
require('../Shaders/ShaderMatcapGradient');
require('../Shaders/ShaderRainbow');
require('../Shaders/ShaderSkyGradient');
require('../Scripts/CustomKeyboardControls');
require('../Scripts/CustomTouchControls');
require('../Scripts/CustomRotationControls');
require('../Scripts/EventListener');
require('../Scripts/LookAt');
require('../Scripts/MobileLookControls');
require('../Scripts/OculusThumbstickMovement');
require('../Scripts/OculusThumbstickRotation');
require('../Scripts/RotateNFTHighlighters');
require('../Scripts/SimpleNavmeshContraint')
const AFRAME = window.AFRAME;
const THREE = window.THREE;



export default function MainScene({ owner = null, nfts = null, gallery = null, navMesh = null, coordinates = null, startCoordinates = null, oceanCoordinates = null, holderCoordinates = null, nftCoordinates = null }) {
    if (getCookieConsentValue() === 'true') {
        logVisitRealm();
    }
    const is_localhost = window.location.href.indexOf("localhost") > -1;
    if (is_localhost) {
        // console.log("owner", owner);
        // console.log("nfts", nfts);
        // console.log("gallery", gallery);
        // console.log("navMesh", navMesh);
        // console.log("coordinates", coordinates);
        // console.log("startCoordinates", startCoordinates);
        // console.log("oceanCoordinates", oceanCoordinates);
        // console.log("holderCoordinates", holderCoordinates);
        // console.log("nftCoordinates", nftCoordinates);
    }
    const get_gallery_file = "https://galleryapi.cronosragdolls.com/api/get_gallery_file";
    const get_media_file = "https://galleryapi.cronosragdolls.com/api/get_nft_media";
    var RAGContract = "0x21E68422011a330beFdf45b0E45072c947316C95";
    const nfts_per_holder = 10;
    const var_nfts = nfts;
    const var_owner = owner;
    const var_gallery = gallery;
    const var_navmesh = navMesh;
    const var_theme = "2023_autumn";
    const var_Coordinates = coordinates.data;
    const var_startCoordinates = "-" + (startCoordinates.x) + ' ' + startCoordinates.y + ' ' + (startCoordinates.z + 2.5);
    const var_holderCoordinates = holderCoordinates;
    const var_nftCoordinates = nftCoordinates;
    const var_oceanCoordinates = oceanCoordinates;
    const enable_stats = true;
    try {
        // Create an array with the elements of nfts.data by iterating over the array
        var nftArray = [];
        var ragArray = [];

        Object.keys(var_nfts.data).map((e, i) => {
            // Iterate over e and push the elements to nftArray using a for loop
            if (e.toLowerCase() !== RAGContract.toLowerCase()) {
                for (let i = 0; i < var_nfts.data[e].length; i++) {
                    if (var_nfts.data[e][i].token_id !== undefined) {
                        nftArray.push(var_nfts.data[e][i]);
                    }
                }
            }
            else {
                for (let i = 0; i < var_nfts.data[e].length; i++) {
                    ragArray.push(var_nfts.data[e][i]);
                }
            }
        });

        function calculateDistance(x1, y1, z1, x2, y2, z2) {
            return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2 + (z2 - z1) ** 2);
        }

        var holderMap = holderCoordinates.map((holder, index) => {
            // Find the nearest placeholders
            var placeholderDistanceArray = [];
            var_nftCoordinates.forEach((placeholder, placeholder_index) => {
                var distance = calculateDistance(-holder.x, holder.y, holder.z, -placeholder.x, placeholder.y, placeholder.z);
                placeholderDistanceArray.push({ distance, placeholder });
            });

            // Get the nearst placeholders up to the number of nfts_per_holder
            var nearestPlaceholders = placeholderDistanceArray.sort((a, b) => a.distance - b.distance).slice(0, nfts_per_holder);

            return {
                coordinates: holder,
                ragdoll: ragArray[index],
                nfts: nftArray.slice(index * nfts_per_holder, (index + 1) * nfts_per_holder), // Example: Assign 10 NFTs per holder
                placeholders: nearestPlaceholders
            };
        });

        // Create a function that checks every second the distance between the camera and the holders    
        function imageLoaderByDistance() {
            var camera = document.querySelector('#cameraRig');
            var cameraPosition = camera.getAttribute('position');
            var distanceArray = [];
            holderMap.forEach((holder, index) => { // Loop through the holderMap
                const distance = calculateDistance(cameraPosition.x, cameraPosition.y, cameraPosition.z, -holder.coordinates.x, holder.coordinates.y, holder.coordinates.z);
                distanceArray.push({ distance, index }); // Push the distance and the index of the holder to the distanceArray
            })

            // Iterate over holderMap and load its media
            holderMap.map((holder, index) => {
                var rag_id = "RAGNFT_" + index + "_" + var_owner;
                var nft_media_rag = get_media_file + "/" + RAGContract.toLowerCase() + "/" + holder.ragdoll.token_id;
                var coords = holder.coordinates;
                // Adds <img key={rag_id} id={rag_id} src={nft_media_rag} crossOrigin="anonymous" alt="nft" /> to a-assets
                if (!document.querySelector('#' + rag_id)) {
                    var img = document.createElement('img');
                    img.setAttribute('id', rag_id);
                    img.setAttribute('src', nft_media_rag);
                    img.setAttribute('crossOrigin', "anonymous");
                    document.querySelector('a-assets').appendChild(img);

                    var coords_nft = (-coords.x) + ' ' + (coords.y + 12) + ' ' + coords.z;
                    var rag_id = "RAGNFT_" + index + "_" + var_owner;
                    // Adds <a-image key={"RAGHolder_" + rag_id} material={"shader: flat; src: #" + rag_id} look-at-player="fixRotationX: true; fixRotationY: false; fixRotationZ: true" position={coords_nft} rotation="0 0 0" scale="6 6 6" color="white"></a-image>

                    var ragImage = document.createElement('a-image');
                    ragImage.setAttribute('id', "RAGHolder_" + index);
                    ragImage.setAttribute('material', "shader: flat; src: #" + rag_id);
                    ragImage.setAttribute('look-at-player', "fixRotationX: true; fixRotationY: false; fixRotationZ: true");
                    ragImage.setAttribute('position', coords_nft);
                    ragImage.setAttribute('rotation', "0 0 0");
                    ragImage.setAttribute('scale', "6 6 6");
                    document.querySelector('a-scene').appendChild(ragImage);
                }
            })

            // Find the nearest holder
            const nearestHolder = distanceArray.reduce((prev, curr) => {
                return prev.distance < curr.distance ? prev : curr;
            });
            if (nearestHolder.distance < 60) {
                if (is_localhost) {
                    console.log("Nearest holder: " + nearestHolder.distance);
                    console.log("Nearest holder NFT count: " + holderMap[nearestHolder.index].nfts.length);
                }
                // Load the media of the nearest holder
                let holder = holderMap[nearestHolder.index]
                holder.nfts.forEach((nft, nft_idx) => {
                    var nft_index = nft_idx + (nearestHolder.index * nfts_per_holder);
                    var nft_id = "NftImage_" + nearestHolder.index + "_" + nft_index + "_" + var_owner;
                    var placeholder_id = "NftPlaceholder_" + nearestHolder.index + "_" + nft_index + "_" + var_owner;
                    // Check if nft_id already exists in a-assets
                    if (!document.querySelector('a-scene').querySelector('#' + placeholder_id)) {
                        if (is_localhost) {
                            console.log(nft)
                        }
                        var nft_media = get_media_file + "/" + nft._contract_address.toLowerCase() + "/" + nft.token_id;
                        var coords_nft = holder.placeholders[nft_idx].placeholder;

                        // if nft.image contains .png, .jpg, or .jpeg
                        if (nft.image.indexOf(".mp4") === -1 && nft.image.indexOf(".gif") === -1) {
                            // Add to a-assets the image
                            var img = document.createElement('img');
                            img.setAttribute('key', nft_id);
                            img.setAttribute('id', nft_id);
                            img.setAttribute('src', nft_media);
                            img.setAttribute('crossOrigin', "anonymous");
                            document.querySelector('a-assets').appendChild(img);

                            // Add to a-scena the placeholder
                            var nft_placeholder = document.createElement('a-image');
                            nft_placeholder.setAttribute('id', placeholder_id);
                            nft_placeholder.setAttribute('material', "shader: flat; src: #" + nft_id);
                            nft_placeholder.setAttribute('look-at-player', "fixRotationX: true; fixRotationY: false; fixRotationZ: true");
                            nft_placeholder.setAttribute('position', - coords_nft.x + " " + (coords_nft.y + 2) + " " + coords_nft.z);
                            nft_placeholder.setAttribute('rotation', "0 0 0");
                            nft_placeholder.setAttribute('scale', "3 3 3");
                            document.querySelector('a-scene').appendChild(nft_placeholder);
                        } else {
                            // Add to a-assets the video
                            var video = document.createElement('video');
                            video.setAttribute('key', nft_id);
                            video.setAttribute('id', nft_id);
                            video.setAttribute('src', nft_media);
                            video.setAttribute('crossOrigin', "anonymous");
                            video.setAttribute('autoplay', "true");
                            video.setAttribute('loop', "true");
                            video.setAttribute('playsinline', "true");
                            video.setAttribute('webkit-playsinline', "true");
                            video.setAttribute('muted', "true");
                            document.querySelector('a-assets').appendChild(video);

                            // Add to a-scena the placeholder
                            var nft_placeholder = document.createElement('a-video');
                            nft_placeholder.setAttribute('id', placeholder_id);
                            nft_placeholder.setAttribute('material', "shader: flat; src: #" + nft_id);
                            nft_placeholder.setAttribute('look-at-player', "fixRotationX: true; fixRotationY: false; fixRotationZ: true");
                            nft_placeholder.setAttribute('position', - coords_nft.x + " " + (coords_nft.y + 2) + " " + coords_nft.z);
                            nft_placeholder.setAttribute('rotation', "0 0 0");
                            nft_placeholder.setAttribute('scale', "3 3 3");
                            document.querySelector('a-scene').appendChild(nft_placeholder);
                        }

                        var shaderType = ""
                        // Iterate over holder.ragdoll.attributes and find the trait_type "Display" and assign it to a variable
                        holder.ragdoll.attributes.forEach((attribute) => {
                            if (attribute.trait_type === "Display") {
                                shaderType = attribute.value
                            }
                        })

                        // MAke if cases for each shaderType: Common, Rare, Epic, Legendary
                        var rarityColor = "";
                        var shader = "";
                        if (shaderType === "Common") {
                            rarityColor = "#40464D";
                            shader = "hologram; mixColorGradient: " + rarityColor + ";  colorGradient: #40464D;"
                        } else if (shaderType === "Rare") {
                            rarityColor = "#008DD4";
                            shader = "hologram; mixColorGradient: " + rarityColor + "; colorGradient: #008DD4;"
                        } else if (shaderType === "Epic") {
                            rarityColor = "#8A2BE2";
                            shader = "hologram; mixColorGradient: " + rarityColor + "; colorGradient: #8A2BE2;"
                        } else if (shaderType === "Legendary") {
                            rarityColor = "#DE6E0E";
                            shader = "hologram; mixColorGradient: " + rarityColor + "; colorGradient: #DE6E0E;"
                        }

                        // Create a plane as background for the text
                        var textBackground = document.createElement('a-plane');
                        textBackground.setAttribute('class', 'textBackground')
                        textBackground.setAttribute('look-at-player', "fixRotationX: true; fixRotationY: false; fixRotationZ: true");
                        textBackground.setAttribute('width', '3');
                        textBackground.setAttribute('height', '1');
                        textBackground.setAttribute('material', 'shader: ' + shader);
                        textBackground.setAttribute('position', (-coords_nft.x) + " " + (coords_nft.y) + " " + coords_nft.z);
                        document.querySelector('a-scene').appendChild(textBackground);

                        // Create a plane as background for the text
                        var textBackground = document.createElement('a-plane');
                        textBackground.setAttribute('class', 'textBackground')
                        textBackground.setAttribute('look-at-player', "fixRotationX: true; fixRotationY: false; fixRotationZ: true");
                        textBackground.setAttribute('width', '3');
                        textBackground.setAttribute('height', '1');
                        textBackground.setAttribute('color', '#FFFFFF');
                        textBackground.setAttribute('material', 'shader: ' + shader);
                        textBackground.setAttribute('position', (-coords_nft.x) + " " + (coords_nft.y + 4) + " " + coords_nft.z);
                        document.querySelector('a-scene').appendChild(textBackground);

                        // Add an <a-torus> to the scene
                        var torus = document.createElement('a-torus');
                        torus.setAttribute('geometry', "primitive: torus; segmentsRadial: 4; segmentsTubular: 8; radius: 1.0; radiusTubular: 0.2")
                        torus.setAttribute('rotation', "90 0 0")
                        torus.setAttribute('material', 'shader: ' + shader);
                        torus.setAttribute('position', (-coords_nft.x) + " " + (coords_nft.y - 1) + " " + coords_nft.z);
                        document.querySelector('a-scene').appendChild(torus);

                        // Create a text element for the name
                        var nftText = nft.name.indexOf(nft.token_id) > -1 ? nft.name : nft.name + " #" + nft.token_id;

                        if (nftText.length >= 20) {
                            // Add a \n every 20 characters in the last space before the 20th character
                            var index = 20;
                            while (index > 0) {
                                if (nftText.charAt(index) === " ") {
                                    nftText = nftText.substring(0, index) + "\n" + nftText.substring(index + 1);
                                    break;
                                }
                                index--;
                            }
                        }

                        var nftNameText = document.createElement('a-text');
                        nftNameText.setAttribute('class', 'nftNameText')
                        nftNameText.setAttribute('look-at-player', "fixRotationX: true; fixRotationY: false; fixRotationZ: true");
                        nftNameText.setAttribute('value', nftText);
                        nftNameText.setAttribute('color', '#ffffff');
                        nftNameText.setAttribute('align', 'center');
                        nftNameText.setAttribute('wrap-count', '40');
                        nftNameText.setAttribute('position', (-coords_nft.x) + " " + (coords_nft.y) + " " + coords_nft.z);
                        document.querySelector('a-scene').appendChild(nftNameText);

                        // Add a torus for each placeholder
                        var coords_torus = (-holder.coordinates.x) + ' ' + (holder.coordinates.y - 1) + ' ' + holder.coordinates.z;
                        var torus = document.createElement('a-torus');
                        var torusShader = "shader: hologram; mixColorGradient: " + rarityColor + ";  colorGradient: " + rarityColor + ";"
                        torus.setAttribute('geometry', "primitive: torus; radius: 9; radiusTubular: 0.19");
                        torus.setAttribute('material', torusShader);
                        torus.setAttribute('position', coords_torus);
                        document.querySelector('a-scene').appendChild(torus);

                        torus = document.createElement('a-torus');
                        torus.setAttribute('geometry', "primitive: torus; radius: 9; radiusTubular: 0.19");
                        torus.setAttribute('material', torusShader);
                        torus.setAttribute('position', coords_torus);
                        torus.setAttribute('rotation', "0 90 0");
                        document.querySelector('a-scene').appendChild(torus);

                        // Add a particle system in the position of the holder
                        var coordsParticleSystem = (-holder.coordinates.x) + ' ' + (holder.coordinates.y - 5) + ' ' + holder.coordinates.z;
                        var particleSystem = document.createElement('a-entity');
                        particleSystem.setAttribute('id', 'particleSystemNFTHolder');
                        particleSystem.setAttribute('particle-system', 'color: ' + rarityColor + "," + rarityColor + "; maxAge: 2; size: 1; texture: images/cronos_particle.png; maxParticleCount: 1000; duration: 2; velocitySpread: 30 30 30; accelerationSpread: 1 1 1");
                        particleSystem.setAttribute('position', coordsParticleSystem);
                        document.querySelector('a-scene').appendChild(particleSystem);
                    }
                });
            }
            else {
                // Remove elements with class nftNameText
                var elements = document.getElementsByClassName("nftNameText");
                while (elements.length > 0) {
                    elements[0].parentNode.removeChild(elements[0]);
                }
                // Remove elements with class textBackground
                var elements = document.getElementsByClassName("textBackground");
                while (elements.length > 0) {
                    elements[0].parentNode.removeChild(elements[0]);
                }

                // Remove assets with id starting with nft_
                var assets = document.querySelector('a-assets');
                var assets_children = assets.children;
                for (let i = 0; i < assets_children.length; i++) {
                    if (assets_children[i].id.startsWith("NftImage_")) {
                        assets.removeChild(assets_children[i]);
                    }
                }
                // Remove assets with id starting with nft_
                var assets = document.querySelector('a-scene');
                var assets_children = assets.children;
                for (let i = 0; i < assets_children.length; i++) {
                    if (assets_children[i].id.startsWith("NftPlaceholder_")) {
                        assets.removeChild(assets_children[i]);
                    }
                }

                // Remove all of the <a-torus>
                var torus = document.querySelectorAll('a-torus');
                for (let i = 0; i < torus.length; i++) {
                    torus[i].parentNode.removeChild(torus[i]);
                }

                // Remove particleSystemNFTHolder
                var particleSystemNFTHolder = document.querySelector('#particleSystemNFTHolder');
                if (particleSystemNFTHolder) {
                    particleSystemNFTHolder.parentNode.removeChild(particleSystemNFTHolder);
                }
            }
        }
        // Start the timer if the scene is loaded
        setInterval(imageLoaderByDistance, 1000);

        // Check if it's a touch device
        var isTouchDevice = 'ontouchstart' in document.documentElement;
        // Check if it VR headset
        var isVRDevice = AFRAME.utils.device.checkHeadsetConnected();
        // Check if its a desktop
        var isDesktop = !isTouchDevice && !isVRDevice;

        // Create a function to log the coordinates of the camera
        function logCameraPosition() {
            var camera = document.querySelector('#cameraRig');
            var cameraPosition = camera.getAttribute('position');
            console.log("Position: ");
            console.log(cameraPosition);
            var cameraRotation = camera.getAttribute('rotation');
            console.log("Rotation: ");
            console.log(cameraRotation);
        }

        // call logCameraPosition() every second if the url is localhost
        if (is_localhost) {
            setInterval(logCameraPosition, 20000);
        }
    } catch (e) {
        console.log(e);
    }

    // Adds event listener to model-loaded event
    // If gltf-loaded-event-listener is not registered, then register it:
    if (!AFRAME.components['gltf-loaded-event-listener']) {
        AFRAME.registerComponent('gltf-loaded-event-listener', {
            init: function () {
                // We are now inside the A-Frame component lifecycle, 'this.el' is the current A-Frame element
                // to which this component is attached. Hence, we can add an event listener directly to it.
                this.el.addEventListener('model-loaded', this.onModelLoaded.bind(this));
            },
            onModelLoaded: function (evt) {
                // If its localhost, add stats as attribute to <a-scene>
                if (is_localhost && enable_stats) {
                    document.querySelector('a-scene').setAttribute('stats', '');
                }

                // Check if it's mobile
                var head = document.querySelector('#head');

                if (!AFRAME.utils.device.isMobile()) {
                    head.setAttribute('look-controls', "magicWindowTrackingEnabled:false; pointerLockEnabled: false; reverseMouseDrag: false");
                } else {
                    // Add to the <a-scene> 'device-orientation-permission-ui="enabled: false"'
                    document.querySelector('a-scene').setAttribute('device-orientation-permission-ui', "enabled: false");
                    head.setAttribute('rotation-controls', "speed:0.3");
                }

                // Changes the speed per device
                var speed = 0.3;
                if (AFRAME.utils.device.checkHeadsetConnected()) {
                    speed = 1.0;
                }
                var cameraRig = document.querySelector('#cameraRig');
                // Add speed to the movement-controls attribute
                cameraRig.setAttribute('movement-controls', "speed: " + speed + "; constrainToNavMesh: true; controls: checkpoint, gamepad, trackpad, customtouch, customkeyboard");

                // add fog as attribute to <a-scene>
                // If the time is between 6:00 and 8:00 set the fog to #FFE15D
                var date = new Date();
                var hour = date.getHours();
                var horizonColor = "#FFE15D";
                var timePreset = "";
                var timeShadows = "";
                var timeFogAmount = "";
                var timeSkyType = "gradient";
                var timeLightDistance = "100";
                var force_theme = "";
                if (is_localhost && false) {
                    // For development
                    horizonColor = "#b7d7ff";
                    timePreset = "starry";
                    timeShadows = "false";
                    timeFogAmount = ".1";
                    timeSkyType = "atmosphere";
                    timeLightDistance = "200"

                } else {
                    if ((force_theme === "" && (hour >= 5 && hour <= 8)) || force_theme === "5-8") {
                        horizonColor = "#FFE15D";
                        timePreset = "dream";
                        timeShadows = "true";
                        timeFogAmount = ".6";
                        timeSkyType = "gradient";
                        timeLightDistance = "100"
                    }
                    else if ((force_theme === "" && (hour >= 8 && hour <= 18)) || force_theme === "8-18") {
                        horizonColor = "#E6FFFD";
                        timePreset = "dream";
                        timeShadows = "true";
                        timeFogAmount = ".25";
                        timeSkyType = "gradient";
                        timeLightDistance = "100"
                    }
                    else if ((force_theme === "" && (hour >= 18 && hour <= 20)) || force_theme === "18-20") {
                        horizonColor = "#F49D1A";
                        timePreset = "dream";
                        timeShadows = "true";
                        timeFogAmount = ".6";
                        timeSkyType = "gradient";
                        timeLightDistance = "100"
                    }
                    else if ((force_theme === "" && hour >= 20) || force_theme === ">=20") {
                        horizonColor = "#b7d7ff";
                        timePreset = "starry";
                        timeShadows = "false";
                        timeFogAmount = ".1";
                        timeSkyType = "atmosphere";
                        timeLightDistance = "200"
                    }
                }
                // Add a light facing the oposite direction of the camera: 
                // <a-entity light="type: point; intensity: 0.5; distance: 100; decay: 2; castShadow: true;" position="0 0 0" rotation="0 180 0"></a-entity>
                var light = document.createElement('a-entity');
                light.setAttribute('light', "type: point; intensity: 0.5; distance: " + timeLightDistance + "; decay: 2; castShadow: true;");
                light.setAttribute('position', "0 0 0");
                light.setAttribute('rotation', "0 180 0");
                document.querySelector('#head').appendChild(light);

                // Adds to scene: <a-entity environment="preset: dream; skyType: gradient; horizonColor: #ffd966; ground: flat; groundTexture: none; groundColor: #9fc5e8; grid: dots; gridColor: #00e5ff; dressing: none" scale="3, 3, 3" position="-214.0 -0.1 214.0"></a-entity>
                var environment = document.createElement('a-entity');
                environment.setAttribute('environment', "preset: " + timePreset + ";skyType: " + timeSkyType + "; shadows:" + timeShadows + "; fog:" + timeFogAmount + ";horizonColor: " + horizonColor + "; ground: none; groundTexture: none; groundColor: #9fc5e8; grid: dots; gridColor: #00e5ff; dressing: none");
                environment.setAttribute('scale', "3, 3, 3");
                environment.setAttribute('position', -startCoordinates.x + " " + startCoordinates.y + " " + startCoordinates.z);
                document.querySelector('a-scene').appendChild(environment);

                // If it's not a VR headset
                // Find the element loading_data and erases it
                var loading_data = document.getElementById("loading_data");
                loading_data.parentNode.removeChild(loading_data);

                var loading_data_plane = document.getElementById("loading_data_plane");
                loading_data_plane.parentNode.removeChild(loading_data_plane);

                // Do something when model has loaded
                // If it's localhost, log the model
                if (is_localhost) {
                    console.log("Model loaded");
                }
                const obj = this.el.getObject3D('mesh');
                // Go over the submeshes and modify materials we want.
                // Pre-compute reusable variables outside of the traversal loop

                // Important: Put variables outside the loop to avoid memory leak
                // Important: Load materials before if needed
                const blackEmissiveColor = new THREE.Color(0x101010);
                const matcapTexture_amber = new THREE.TextureLoader().load("images/matcaps/matcap_amber.png");
                const matcapTexture_green_2 = new THREE.TextureLoader().load("images/matcaps/matcap_green_2.png");
                const matcapTexture_green_toon = new THREE.TextureLoader().load("images/matcaps/matcap_green_toon.png");
                const matcapTexture_green = new THREE.TextureLoader().load("images/matcaps/matcap_green.png");
                const matcapTexture_grey_border = new THREE.TextureLoader().load("images/matcaps/matcap_grey_border.png");
                const matcapTexture_grey = new THREE.TextureLoader().load("images/matcaps/matcap_grey.png");
                const matcapTexture_honey = new THREE.TextureLoader().load("images/matcaps/matcap_honey.png");
                const matcapTexture_nidorx = new THREE.TextureLoader().load("images/matcaps/matcap_nidorx.jpg");
                const matcapTexture_warm_cold_2 = new THREE.TextureLoader().load("images/matcaps/matcap_warm_cold_2.png");
                const matcapTexture_warm_cold_3 = new THREE.TextureLoader().load("images/matcaps/matcap_warm_cold_3.png");
                const matcapTexture_warm_cold = new THREE.TextureLoader().load("images/matcaps/matcap_warm_cold.png");
                const matcapTexture_water_2 = new THREE.TextureLoader().load("images/matcaps/matcap_water_2.png");
                const matcapTexture_water_3 = new THREE.TextureLoader().load("images/matcaps/matcap_water_3.png");
                const matcapTexture_water_4 = new THREE.TextureLoader().load("images/matcaps/matcap_water_4.png");
                const matcapTexture_water_shiny = new THREE.TextureLoader().load("images/matcaps/matcap_water_shiny.png");
                const matcapTexture_water = new THREE.TextureLoader().load("images/matcaps/matcap_water.png");

                const vertexShader = `
                varying vec3 v_normal;
                varying vec3 v_viewDir;  // Added this line to store the view direction
                uniform float viewDirBlend; // Added this line for the view blending factor

                void main() {
                v_normal = normalize(normalMatrix * normal);
                
                // Calculate view direction in model-view space
                vec4 vertex = modelViewMatrix * vec4(position, 1.0);
                v_viewDir = normalize(-vertex.xyz);
                
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
                `;

                const fragmentShader = `
                varying vec3 v_normal;
                varying vec3 v_viewDir; // Added this line to accept the view direction
                uniform sampler2D matcap;
                uniform float viewDirBlend; // Added this line for the view blending factor
                uniform vec3 originalColor;

                void main() {
                vec3 blendedNormal = normalize(v_normal + v_viewDir * viewDirBlend);
                vec2 uv = blendedNormal.xy * 0.5 + 0.5;
                
                vec4 matcapColor = texture2D(matcap, uv);
                
                // vec3 finalColor = mix(originalColor, matcapColor.rgb, 0.4);
                
                // Additive Blending
                // vec3 finalColor = originalColor + matcapColor.rgb;
                
                // Multiplicative Blending
                // vec3 finalColor = originalColor * matcapColor.rgb;
                
                // Screen Blending
                // vec3 finalColor = 1.0 - (1.0 - originalColor) * (1.0 - matcapColor.rgb);
                
                // Overlay Blending
                // vec3 finalColor = mix(
                //     2.0 * originalColor * matcapColor.rgb,
                //     1.0 - 2.0 * (1.0 - originalColor) * (1.0 - matcapColor.rgb),
                //     step(0.5, originalColor)
                // );
                
                // Fresnel Effect
                float fresnel = clamp(dot(normalize(v_normal), normalize(v_viewDir)), 0.7, 1.0);
                vec3 finalColor = mix(matcapColor.rgb, originalColor, fresnel);

                gl_FragColor = vec4(finalColor, 1.0);
                }
                `;

                const matcapMaterial_amber = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_amber },
                        originalColor: { value: new THREE.Color(0xffffff) }, // Placeholder, will be overridden
                        viewDirBlend: { value: 0.5 } // Add this line for the blend factor
                    }
                });
                const matcapMaterial_green_2 = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_green_2 },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_green_toon = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_green_toon },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_green = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_green },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_grey_border = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_grey_border },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_grey = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_grey },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_honey = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_honey },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_nidorx = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_nidorx },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_warm_cold_2 = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_warm_cold_2 },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_warm_cold_3 = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_warm_cold_3 },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_warm_cold = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_warm_cold },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_water_2 = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_water_2 },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_water_3 = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_water_3 },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_water_4 = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_water_4 },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_water_shiny = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_water_shiny },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });
                const matcapMaterial_water = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    uniforms: {
                        matcap: { value: matcapTexture_water },
                        originalColor: { value: new THREE.Color(0xffffff) } // Placeholder, will be overridden
                    }
                });

                obj.traverse(node => {
                    // Only apply it for elements without children, meshes, and standard materials
                    if (node.children.length === 0 && node.isMesh && node.material.type === 'MeshStandardMaterial') {
                        var needsUpdate = false;
                        // If it's House change material
                        if (node.name.startsWith("AllModels_fbx_house")) {
                            if (is_localhost) {
                                console.log("house");
                            }
                            // Preserve the original color
                            matcapMaterial_warm_cold_2.uniforms.originalColor.value = node.material.color;
                            node.material = matcapMaterial_warm_cold_2.clone(); // Clone so each mesh can have a different original color
                            needsUpdate = true;
                        }
                        // If it's Floor change material
                        if (node.name.startsWith("Floor")) {
                            if (is_localhost) {
                                console.log("floor");
                            }
                            matcapMaterial_nidorx.uniforms.originalColor.value = node.material.color;
                            node.material = matcapMaterial_nidorx.clone(); // Clone so each mesh can have a different original color
                            needsUpdate = true;
                        }
                        // If it's Tower change material
                        if (node.name.startsWith("AllModels_fbx_tower")) {
                            if (is_localhost) {
                                console.log("tower");
                            }
                            matcapMaterial_grey.uniforms.originalColor.value = node.material.color;
                            node.material = matcapMaterial_grey.clone(); // Clone so each mesh can have a different original color
                            needsUpdate = true;
                        } else {
                            node.material.metalness = 0.0;
                            node.material.roughness = 1.0;
                            node.material.emissive = blackEmissiveColor;
                            needsUpdate = true;
                        }
                        node.material.needsUpdate = needsUpdate;
                    }
                });

                if (is_localhost) {
                    // Clears the console
                    console.clear();
                }
            }
        });
    }

    return (
        <>
            <a-scene
                renderer="antialias: true; 
                          colorManagement: false;
                          sortObjects: true;
                          physicallyCorrectLights: false;
                          precision: low;
                          toneMapping: ACESFilmic"
                loading-screen="dotsColor: white; backgroundColor: black"
                id="main-scene"
                ar-hit-test="enabled: false;"
                device-orientation-permission-ui="enabled: false"
            >
                {
                    window.location.href.indexOf('localhost') > -1 &&
                    <>
                        <a-entity geometry="primitive: sphere" material="shader:matcap-shader;" position={"-" + (startCoordinates.x - 2) + ' ' + startCoordinates.y + 2 + ' ' + (startCoordinates.z + 2.5)}></a-entity>
                        <a-entity geometry="primitive: box" material="shader:matcap-shader-gradient;" position={"-" + (startCoordinates.x + 2) + ' ' + startCoordinates.y + 2 + ' ' + (startCoordinates.z + 2.5)}></a-entity>
                    </>
                }

                <a-assets timeout="7200">
                    <img id="logo" src="/images/white_logo.png" />
                    <img id="sky" src="/images/sky5.png" />
                    <img id="burnedmetal" src="/images/burnedmetal.jpg" />
                    <img id="concrete" src="/images/concrete.jpg" />
                    <img id="wood" src="/images/wood.jpg" />
                    <img id="concrete_blue" src="/images/concrete_blue.png" />
                    <img id="matcap_texture" src="/images/matcap_nidorx.jpg" />
                    <img id="cronos_particle" src="/images/cronos_particle.png" />
                </a-assets>
                {/* <a-entity position="0 0 0" scale="1 1 1"></a-entity> */}
                <a-entity id={"navmesh_" + Date.now()} position="0 0 0" nav-mesh visible="false" gltf-model={`url(${get_gallery_file}/${var_owner}/${var_theme}/navmesh?#${Date.now()})`} />
                <a-entity gltf-loaded-event-listener id={"gallery_" + Date.now()} position="0 0 0" visible="true" gltf-model={`url(${get_gallery_file}/${var_owner}/${var_theme}/gallery?#${Date.now()})`} />

                <a-text value="Welcome, chosen one. Seek the diamonds to discover your NFT arsenal.\n\n Desktop adventurers, use AWSD and mouse.\n\nMobile warriors, tap to advance, drag to explore.\n\nThe quest begins now.\n\nCome at different times to see how the world changes."
                    align="left" position={(-startCoordinates.x - .8) + " " + (startCoordinates.y + 3.5) + " " + (startCoordinates.z)}
                    color="#000" width="2" anchor="left" baseline="top" wrap-count="30"></a-text>

                {/* Show some hints in where to finx the NFT rooms */}
                {
                    var_holderCoordinates.map((coords, index) => {
                        if (holderMap[index].nfts.length > 0) {
                            var coords_holder = (-coords.x) + ' ' + (coords.y + 40) + ' ' + coords.z;
                            var coords_holder_animation = (-coords.x) + ' ' + (coords.y + 40) + ' ' + coords.z;
                            var animation = "property: rotation; from: 0 180 180; to: 0 540 180; dur: 10000; easing: linear; loop: true";
                            var highlightColor = "darkred";
                            // Check if the nfts in holderMap[index] contain a video or gif
                            for (var i = 0; i < holderMap[index].nfts.length; i++) {
                                if (holderMap[index].nfts[i].image.indexOf(".gif") > -1 || holderMap[index].nfts[i].image.indexOf(".mp4") > -1) {
                                    highlightColor = "darkgreen";
                                    break;
                                }
                            }
                            return (<a-octahedron id={"NftHighlighter_" + index} material="shader: standard; metalness: .5" key={"NftHolder_" + index} position={coords_holder} rotation="0 0 180" scale="5 10 5" color={highlightColor}></a-octahedron>)
                        }
                    })
                }

                <a-plane color="#064273" material="shader: hologram; mixColorGradient: #034087;  colorGradient: #034087;" position={oceanCoordinates} scale="500 500 1" rotation="-90"></a-plane>
                {/* Wait until nav-mesh is ready */}
                <a-entity id="cameraRig"
                    movement-controls="constrainToNavMesh: true; controls: checkpoint, gamepad, trackpad, customtouch, customkeyboard;"
                    position={var_startCoordinates} rotation="0 0 0">
                    <a-entity id="head"
                        camera="active: true" position="0 2.0 0"
                    >
                        <a-plane id="loading_data_plane" position="0 -0.5 -1.5" width="50" height="50" material="shader: flat; color: black "></a-plane>
                        <a-text id="loading_data" value="Sacred artifacts are aligning...\n\nThe gallery of battles and magic awaits!" align="center" width="1" height="2" wrap-count="15" position="0 0 -1.5" color="#FFF"></a-text>
                    </a-entity>
                    <a-entity oculus-touch-controls="hand: left"></a-entity>
                    <a-entity oculus-touch-controls="hand: right"></a-entity>
                </a-entity>
            </a-scene>
        </>
    );
}