// src/GalleryLoader.js

import React, { useEffect, useState } from 'react';
import NFTApi from './api/NFTApi';
import { MainScene } from './components/VRScene/VRComponents';
import LandingPage from './components/LandingPage';

const THREE = window.THREE;

function GalleryLoader() {
    const [owner, setOwner] = useState(null);
    const [nfts, setNfts] = useState([]);
    const [gallery, setGallery] = useState(null);
    const [navMesh, setNavMesh] = useState(null);
    const [coordinates, setCoordinates] = useState([]);
    const [startCoordinates, setStartCoordinates] = useState(null);
    const [oceanCoordinates, setOceanCoordinates] = useState(null);
    const [holderCoordinates, setHolderCoordinates] = useState([]);
    const [nftCoordinates, setNftCoordinates] = useState([]);
    const [dataIsLoaded, setDataIsLoaded] = useState(false);
    const [validNFT, setValidNFT] = useState(false);
    const [isMainPath, setIsMainPath] = useState(false);
    // State variable to track whether the device is in landscape mode
    const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
    // State variable to track whether the device is mobile
    const [isMobile, setIsMobile] = useState(/Mobi|Android/i.test(navigator.userAgent));

    // Function to check orientation
    const checkOrientation = () => {
        if (window.innerWidth > window.innerHeight) {
            setIsLandscape(true);
        } else {
            setIsLandscape(false);
        }
    };

    useEffect(() => {
        // Iterate over the THREE cache and delete entreis that start with RAGNFT_, navmesh_, gallery_, NftImage_
        for (let key in THREE.Cache.files) {
            if (key.startsWith("RAGNFT_") || key.startsWith("navmesh_") || key.startsWith("gallery_") || key.startsWith("NftImage_")) {
                THREE.Cache.remove(key);
            }
        }

        // Check orientation when component mounts
        checkOrientation();

        // Listen for window resize events (which include orientation changes)
        window.addEventListener('resize', checkOrientation);

        async function fetchData() {
            try {
                const urlParts = window.location.href.split("://")[1].split("/");

                // If the lenght of urlParts is 1 then the user is on the main path
                // Is the leght is 2 and the second part is / then is on main path                
                if (urlParts.length === 1 || (urlParts.length === 2 && urlParts[1] === "")) {
                    setIsMainPath(true);
                    setDataIsLoaded(true);
                }
                else {
                    var address = urlParts[1];
                    const [nftData, galleryData, navMeshData, coordinatesData] = await Promise.all([
                        NFTApi.getWalletNFTs(address),
                        NFTApi.getGalleryFile(address, "2023_autumn", "gallery"),
                        NFTApi.getGalleryFile(address, "2023_autumn", "navmesh"),
                        NFTApi.getGalleryFile(address, "2023_autumn", "coordinates")
                    ]);

                    setOwner(address);
                    setNfts(nftData);
                    setGallery(galleryData);
                    setNavMesh(navMeshData);
                    setCoordinates(coordinatesData);

                    // Create an array for nftHolders and nftCoordinates
                    var l_nftHolders = [];
                    var l_nftCoordinates = [];

                    for (let i = 0; i < coordinatesData.data.length; i++) {
                        var coordinate = coordinatesData.data[i];
                        if (coordinate['group'] === 'House') {
                            var start_coordinates = coordinate["position"];
                            // The start_coordinates are in the format 66.00 0.00 218.00
                            // split the coorinates into x, y, z and create new variables as float
                            var houseCoordinates = start_coordinates.split(',');
                            var x = parseFloat(houseCoordinates[0]);
                            var y = parseFloat(houseCoordinates[1]);
                            var z = parseFloat(houseCoordinates[2]);
                            var startCoordinates = { x: x, y: y, z: z }
                            var oceanCoordinates = "-" + x + ' ' + (y - 1) + ' ' + z;
                            // replace the commas in start_coordinates with spaces
                            setStartCoordinates(startCoordinates)
                            setOceanCoordinates(oceanCoordinates)
                        }

                        if (coordinate['group'] === 'NFTCatHolder' || coordinate['group'] === 'NFTPlaceholder') {
                            var holderCoordinates = coordinate["position"].split(',');
                            var x = parseFloat(holderCoordinates[0]);
                            var y = parseFloat(holderCoordinates[1]);
                            var z = parseFloat(holderCoordinates[2]);
                            if (coordinate['group'] === 'NFTCatHolder')
                                l_nftHolders.push({ x, y, z });
                            if (coordinate['group'] === 'NFTPlaceholder')
                                l_nftCoordinates.push({ x, y, z });
                        }
                    }
                    setHolderCoordinates(l_nftHolders);
                    setNftCoordinates(l_nftCoordinates);
                    setValidNFT(true);
                    setDataIsLoaded(true);
                }
            } catch (error) {
                console.log("Error fetching data" + error);
                setDataIsLoaded(true);
                setValidNFT(false);
            }
        }

        fetchData();
        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', checkOrientation);
        };
    }, []);

    if (isMainPath) {
        return <LandingPage />;
    }

    if (!dataIsLoaded) {
        return <div className="loading-nfts"><h1> Warrior or Mage, your path is being forged. Prepare for the quest ahead.</h1></div>;
    }

    if (!validNFT) {
        return (
            <div className="loading-nfts">
                <h1> Oops! We need your Cronos address for this quest.</h1>
                <h2> Wanna be an early explorer? Get an invite from our Discord base camp! 🚀</h2>
            </div>
        );
    }

    if (isMobile && isLandscape) {
        return <div className="loading-rotate-screen"><h1>Please rotate the device to portrait position (vertical).</h1></div>;
    }

    return <MainScene owner={owner} nfts={nfts} gallery={gallery} navMesh={navMesh} coordinates={coordinates} startCoordinates={startCoordinates} oceanCoordinates={oceanCoordinates} holderCoordinates={holderCoordinates} nftCoordinates={nftCoordinates} />;
}

export default GalleryLoader;
