// shader-hologram.js
import * as three from "three";
require("aframe");

const AFRAME = window.AFRAME;
const THREE = window.THREE;

AFRAME.registerShader('hologram', {
  schema: {
    colorGradient: { type: 'color', is: 'uniform' },
    mixColorGradient: { type: 'color', is: 'uniform' },
    timeMsec: { type: 'time', is: 'uniform' }
  },

  vertexShader: `
varying vec2 vUv;

void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`,
  fragmentShader: `
varying vec2 vUv;
uniform vec3 colorGradient;
uniform vec3 mixColorGradient;
uniform float timeMsec; // A-Frame time in milliseconds.

void main() {
  float time = timeMsec / 1000.0; // Convert from A-Frame milliseconds to typical time in seconds.
  
  // Add a scanline effect based on x value of UV (Horizontal)
  float scanline = sin(vUv.x * 10.0 + time * 2.0) * 0.5 + 0.5; // Adjust frequency and range
  
  // Add a waving effect to simulate instability (Horizontal)
  float wave = sin(vUv.x * 50.0 + time * 10.0) * 0.5 + 0.5; // Adjust frequency and range
  
  // Calculate final color
  float mixAmount = clamp(scanline + wave, 0.0, 1.0); // Clamp between 0 and 1
  vec3 finalColor = mix(colorGradient, mixColorGradient, mixAmount);
  
  // Some other effect based on time
  finalColor += sin(time + vUv.x * 10.0) * 0.1;
  
  gl_FragColor = vec4(finalColor, 1.0);
}

`
});
