require("aframe");
const AFRAME = window.AFRAME;
const THREE = window.THREE;

module.exports = AFRAME.registerComponent('oculus-thumbstick-rotation', {
    init: function () {
        // Reference to the camera entity
        this.camera = document.querySelector('#camera');

        // Initial rotation
        this.initialRotation = new THREE.Euler(0, 0, 0, 'YXZ');

        // Listen to thumbstickmoved event for rotation
        this.el.addEventListener('thumbstickmoved', this.onThumbstickRotated.bind(this));
    },

    // Function to handle thumbstick movement for rotation
    onThumbstickRotated: function (evt) {
        // Get the thumbstick coordinates from evt.detail.x
        const x = evt.detail.x;

        // Convert the x-value of thumbstick into an angle
        // You may need to adjust this based on your requirement
        const angle = x * 2.0;

        // Get current rotation
        this.initialRotation.copy(this.camera.object3D.rotation);

        // Add angle to the y-component of the rotation
        this.initialRotation.y += THREE.Math.degToRad(angle);

        // Update the rotation of the camera entity
        this.camera.object3D.rotation.copy(this.initialRotation);
    }
});
