// Rotation Component
require("aframe");
const AFRAME = window.AFRAME;
const THREE = window.THREE;

module.exports = AFRAME.registerComponent('rotation-controls', {
    schema: {
        speed: { type: 'number', default: 0.5 },  // speed of rotation
        enabled: { default: true }
    },

    init: function () {
        this.speed = this.data.speed;
        this.initialTouch = { x: 0, y: 0 };
        this.bindMethods();
    },

    bindMethods: function () {
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
    },

    play: function () {
        const canvasEl = this.el.sceneEl.canvas;
        canvasEl.addEventListener('touchstart', this.onTouchStart);
        canvasEl.addEventListener('touchmove', this.onTouchMove);
    },

    pause: function () {
        const canvasEl = this.el.sceneEl.canvas;
        canvasEl.removeEventListener('touchstart', this.onTouchStart);
        canvasEl.removeEventListener('touchmove', this.onTouchMove);
    },

    onTouchStart: function (e) {
        for (let i = 0; i < e.touches.length; i++) {
            if (e.touches[i].clientX < window.innerWidth) {
                this.initialTouch.x = e.touches[i].clientX;
                this.initialTouch.y = e.touches[i].clientY;
                this.touchId = e.touches[i].identifier;
                break;
            }
        }
    },

    onTouchMove: function (e) {
        for (let i = 0; i < e.touches.length; i++) {
            if (e.touches[i].identifier === this.touchId) {
                const dX = e.touches[i].clientX - this.initialTouch.x;
                const dY = e.touches[i].clientY - this.initialTouch.y;

                // Add force depending on the distance of the touch from the center of the screen
                // (the further away from the center, the faster the rotation)
                const distanceFromCenterX = Math.abs(window.innerWidth / 2 - e.touches[i].clientX);
                const distanceFromCenterY = Math.abs(window.innerHeight / 2 - e.touches[i].clientY);
                const distanceFromCenter = Math.sqrt(Math.pow(distanceFromCenterX, 2) + Math.pow(distanceFromCenterY, 2));
                const force = distanceFromCenter / (window.innerWidth / 2);

                const cameraEl = this.el;
                const rotation = cameraEl.getAttribute('rotation');

                cameraEl.setAttribute('rotation', {
                    x: rotation.x - dY * this.speed * force,
                    y: rotation.y - dX * this.speed * force,
                    z: rotation.z
                });

                this.initialTouch.x = e.touches[i].clientX;
                this.initialTouch.y = e.touches[i].clientY;
                break;
            }
        }
    },
});
