require("aframe");

const AFRAME = window.AFRAME;
const THREE = window.THREE;

AFRAME.registerComponent('rotate-all-octahedrons', {
  schema: {
    speed: { type: 'number', default: 0.1 },  // speed of rotation
    max_distance: { type: 'number', default: 100 },  // speed of rotation
  },

  init: function () {
    // Query all octahedrons in the scene
    this.octahedrons = this.el.querySelectorAll('a-octahedron');

    // Initialize target rotations
    this.targetRotations = Array.from(this.octahedrons).map(() => this.getRandomAngle());

    var camera = document.querySelector('#cameraRig');
    this.cameraEl = camera;
  },

  tick: function () {
    // Get the camera position
    this.octahedrons.forEach((el, index) => {
      // Caculate the distance to the camera
      if (el.getAttribute('visible')) { // Only rotate if the octahedron is visible
        var currentRotation = el.getAttribute('rotation');

        // Gradually rotate towards the target
        if (Math.abs(currentRotation.y - this.targetRotations[index]) < 1) {
          this.targetRotations[index] = this.getRandomAngle();  // Generate new target rotation
        }

        // Compute the next rotation
        var nextRotationY = currentRotation.y + this.data.speed;

        // Make sure the rotation is in [0, 360)
        nextRotationY = (nextRotationY + 360) % 360;

        // Set the new rotation 
        el.setAttribute('rotation', { x: currentRotation.x, y: nextRotationY, z: currentRotation.z });
      }
    });
  },

  getRandomAngle: function () {
    return Math.floor(Math.random() * 360);  // Random angle between 0 and 360
  }
});
