// shader-grid-glitch.js
import * as three from "three";
import fragment from './ShaderGradientSkyFragment.js';
import vertex from './ShaderRainbowVertex.js';
require("aframe");


const AFRAME = window.AFRAME;
const THREE = window.THREE;

AFRAME.registerShader('moving-rainbow', {
  schema: {},

  vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
    `,

  fragmentShader: `
      #ifdef GL_ES
      precision mediump float;
      #endif
  
      uniform float timeMsec;
      varying vec2 vUv;
  
      // Function to generate a rainbow color using a value between 0.0 and 1.0
      vec3 rainbowColor(float value) {
        float r = abs(sin(value * 2.0 * 3.14159));
        float g = abs(sin((value + 0.333) * 2.0 * 3.14159));
        float b = abs(sin((value + 0.666) * 2.0 * 3.14159));
        return vec3(r, g, b);
      }
  
      void main() {
        float time = timeMsec / 1000.0; // Convert time to seconds
        float value = mod(vUv.x + time * 0.5, 1.0);
        vec3 color = rainbowColor(value);
  
        gl_FragColor = vec4(color, 1.0);
      }
    `
});
